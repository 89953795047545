import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, throwError, catchError, map, switchMap } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';
import { ActivatedRoute } from '@angular/router';
import { SocketioService } from 'app/shared/services/socketio.service';
import { debug } from 'app/shared/utils';
import { FuseNavigationItem, FuseNavigationService } from '@fuse/components/navigation';
import { defaultNavigation } from 'app/mock-api/common/navigation/data';
import { User } from '../user/user.types';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private _authenticated: boolean = false;
    private _httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    private baseURL = environment.baseUrl;
    private urlWarning = environment.urlWarning;
    private newSiteUrl = environment.newSiteUrl;
    private frontendUrl = environment.frontendUrl;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _fuseNavigationService: FuseNavigationService,
        private _httpClient: HttpClient,
        private _userService: UserService,
        private _socketioService: SocketioService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    /**
     * Getter for method id
     */
    get methodId(): string {
        return localStorage.getItem('method_id') ?? '';
    }

    /**
     * Getter for login email
     */
    get loginEmail(): string {
        return localStorage.getItem('login_email') ?? '';
    }

    /**
     * Getter for refresh token
     */
    get refreshToken(): string {
        return localStorage.getItem('refresh_token') ?? '';
    }

    /**
     * Getter for is authenticated
     */
    get isAuthenticated(): boolean {
        return this._authenticated;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Change password
     *
     * @param input
     */
    changePassword(input: any): Observable<any> {
        const query = 'mutation changePassword($input: changePassword) { changePassword(input: $input) { id }}';
        const body = JSON.stringify({
            query,
            variables: { input }
        });
        return this._httpClient.post(this.baseURL + '/graphql', body, this._httpOptions);
    }

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        const params = new HttpParams().set("userEmail", email);
        return this._httpClient.get(this.baseURL + '/api/v3/user/password/reset', { params: params });
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        const token: string = this._activatedRoute.snapshot.queryParamMap.get('accessToken');
        if (token) {
            this.accessToken = token;
        }
        const query = 'mutation resetPassword($input: Password) { resetPassword(input: $input) { id }}';
        const input = { password: password, confirmPassword: password };
        const body = JSON.stringify({
            query,
            variables: { input }
        })
        return this._httpClient.post(this.baseURL + '/graphql', body, this._httpOptions);
    }

    resetRefresh(flag: any) {
        this.stopRefreshTokenTimer();

        if (flag === 'Y') {
            this.startRefreshTokenTimer();
        }
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        const body = { userEmail: credentials.email, userPswd: credentials.password };

        return this._httpClient.post(this.baseURL + '/api/v3/user/login', body, { observe: 'response' }).pipe(
            switchMap((response: any) => {
                if (response.status === 200) {
                    // Check URL warning
                    if (this.urlWarning) {
                        // check site 45
                        if (response.body.site.id === 45) {
                            const currHref = window.location.href;
                            if (!currHref.startsWith(this.newSiteUrl) && !currHref.startsWith('http://localhost')) {
                                return of({ errors: [{ message: `Please use the following location : ${this.newSiteUrl}/sign-in` }] });
                            }
                        }
                    }

                    // Store the access token in the local storage
                    this.accessToken = response.body.accessToken;

                    // Store user values in the local storage
                    this.setLocalStorage(response.body);

                    // Set current theme
                    this._userService.setTheme();

                    // Set the authenticated flag to true
                    this._authenticated = true;

                    // Store the user on the user service
                    this._userService.user = response.body;

                    // Start refresh token timer
                    if (response.body.stayLoggedIn === 'Y') {
                        this.startRefreshTokenTimer();
                    }

                    // Setup Socket Connection
                    this._socketioService.setupSocketConnection();

                    // Return a new observable with the response
                    return of(response);
                }

            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        if (!this.methodId) {
            return of(false);
        }

        // Renew token
        if (this.methodId === '1') {
            return this._httpClient.get<any>(this.baseURL + '/api/v3/user/token').pipe(
                catchError(() =>

                    // Return false
                    of(false)
                ),
                switchMap((response: any) => {

                    // Store the access token in the local storage
                    this.accessToken = response.accessToken;

                    // Store user values in the local storage
                    this.setLocalStorage(response);

                    // Set the authenticated flag to true
                    this._authenticated = true;

                    // Store the user on the user service
                    this._userService.user = response;

                    // Start refresh token timer
                    if (response.stayLoggedIn === 'Y') {
                        this.startRefreshTokenTimer();
                    }
                    // Return true
                    return of(true);
                })
            );

        } else {
            // Return false
            if (this.loginEmail === '') {
                return of(false);
            }

            let body = {};
            let api = 'unknown';

            switch (this.methodId) {
                case '2':
                    api = '/api/v3/user/azure/refresh';
                    body = { userEmail: this.loginEmail };
                    break;
                case '3':
                    api = '/api/v3/user/google/refresh';
                    body = { userEmail: this.loginEmail };
                    break;
                case '4':
                    api = '/api/v3/user/okta/refresh';
                    body = { refreshToken: this.refreshToken, frontendURL: this.frontendUrl };
                    break;
            }

            return this._httpClient.post<any>(this.baseURL + api, body, this._httpOptions).pipe(
                catchError(() =>

                    // Return false
                    of(false)
                ),
                switchMap((response: any) => {

                    // Store the access token in the local storage
                    this.accessToken = response.accessToken;

                    // Store user values in the local storage
                    this.setLocalStorage(response);

                    // Set the authenticated flag to true
                    this._authenticated = true;

                    // Store the user on the user service
                    this._userService.user = response;

                    // Start refresh token timer
                    if (response.stayLoggedIn === 'Y') {
                        this.startRefreshTokenTimer();
                    }
                    // Return true
                    return of(true);
                })
            );
        }
    }

    signInAzure() {
        // Navigate to the Azure sign in
        window.location.href = this.baseURL + '/api/v3/user/azure/login';
    }

    signInGoogle() {
        // Navigate to the google sign in
        window.location.href = this.baseURL + '/api/v3/user/google/login';
    }

    signInOkta() {
        // Navigate to the OKTA sign in
        window.location.href = this.baseURL + '/api/v3/user/okta/login' + '?frontendURL=' + this.frontendUrl;
    }

    signInRedirect(method: string, data: any) {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        let params = new HttpParams();
        Object.keys(data).forEach(p => {
            params = params.append(p.toString(), data[p].toString());
        });

        let api = 'unknown';

        if (method === 'google') {
            api = '/api/v3/user/google/redirect';
        } else if (method === 'azure') {
            api = '/api/v3/user/azure/redirect';
        } else if (method === 'okta') {
            api = '/api/v3/user/okta/redirect';
        }

        params = params.append('frontendURL', this.frontendUrl);
        return this._httpClient.get(this.baseURL + api, { observe: 'response', params: params }).pipe(
            switchMap((response: any) => {
                if (response.status === 200) {

                    // Check URL warning
                    if (this.urlWarning) {
                        // check site 45
                        if (response.body.site.id === 45) {
                            const currHref = window.location.href;
                            if (!currHref.startsWith(this.newSiteUrl) && !currHref.startsWith('http://localhost')) {
                                return of({ errors: [{ message: `Please use the following location : ${this.newSiteUrl}/sign-in` }] });
                            }
                        }
                    }

                    // Store the access token in the local storage
                    this.accessToken = response.body.accessToken;

                    // Store user values in the local storage
                    this.setLocalStorage(response.body);

                    // Set current theme
                    this._userService.setTheme();

                    // Set the authenticated flag to true
                    this._authenticated = true;

                    // Store the user on the user service
                    this._userService.user = response.body;

                    // Start refresh token timer
                    if (response.body.stayLoggedIn === 'Y') {
                        this.startRefreshTokenTimer();
                    }

                    // Setup Socket Connection
                    this._socketioService.setupSocketConnection();

                    // Return a new observable with the response
                    return of(response);
                }

            })
        );

    }

    /**
     * Sign out
     */
    signOut(scope: any): Observable<any> {
        if (scope === 'all') {
            const params = new HttpParams().set('all', 'true');
            new Promise((resolve, reject) => {
                this._httpClient.get(this.baseURL + '/api/v1/user/logout', { params: params })
                    .subscribe((response: any) => {
                        resolve(response);
                    }, reject);
            });
        } else if (scope !== 'okta') {
            new Promise((resolve, reject) => {
                this._httpClient.get(this.baseURL + '/api/v1/user/logout')
                    .subscribe((response: any) => {
                        resolve(response);
                    }, reject);
            });
        }

        // Remove items from local storage
        this.clearLocalStorage();

        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        // Close Socket Connection
        this._socketioService.disconnectSocketConnection();

        // Set the authenticated flag to false
        this._authenticated = false;

        // Stop refresh timer
        this.stopRefreshTokenTimer();

        // Clear user
        this._userService.user = null;

        // Return the observable
        return of(true);
    }

    /**
     * Sign out -- basic
     */
    signOut2(): Observable<any> {
        // Remove items from local storage
        this.clearLocalStorage();

        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        // Close Socket Connection
        this._socketioService.disconnectSocketConnection();

        // Set the authenticated flag to false
        this._authenticated = false;

        // Stop refresh timer
        this.stopRefreshTokenTimer();

        // Clear user
        this._userService.user = null;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken || this.accessToken === 'undefined') {
            return of(false);
        }

        // Check the signin method availability
        if (this.methodId === '') {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    setLocalStorage(data: any) {
        localStorage.setItem('debug', 'log');  // OPTIONS : log, off
        localStorage.setItem('home_page', data.homePage);
        localStorage.setItem('home_page_url', this.getHomePageUrl(data.homePage));
        localStorage.setItem('login_email', data.email);
        localStorage.setItem('menu_items', JSON.stringify(data.menuItems));
        localStorage.setItem('method_id', data.sso ? data.sso.methodId : '');
        localStorage.setItem('refresh_rate', data.refreshRate);
        localStorage.setItem('refresh_token', data.sso ? (data.sso.refreshToken ? data.sso.refreshToken : '') : '');
        localStorage.setItem('site_name', data.site.name);
        localStorage.setItem('stay_logged_in', data.stayLoggedIn);
        localStorage.setItem('temp_scale', data.tempScale);
        localStorage.setItem('theme_options', data.themeOptions);
        localStorage.setItem('time_zone', data.site.timezone);
    }

    clearLocalStorage() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('debug');
        localStorage.removeItem('home_page');
        localStorage.removeItem('home_page_url');
        localStorage.removeItem('login_email');
        localStorage.removeItem('menu_items');
        localStorage.removeItem('method_id');
        localStorage.removeItem('refresh_rate');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('site_name');
        localStorage.removeItem('stay_logged_in');
        localStorage.removeItem('temp_scale');
        localStorage.removeItem('theme_options');
        localStorage.removeItem('time_zone');
    }

    getHomePageUrl(homePage: any) {
        // Get default
        let _defaultNavigation: FuseNavigationItem[] = defaultNavigation;

        // Get the current navigation
        let nav: FuseNavigationItem[] = _defaultNavigation;

        try {
            const menu = localStorage.getItem('menu_items');
            if (menu) {
                nav = JSON.parse(menu);
            }
        } catch (e) {
            nav = _defaultNavigation;
        }

        if (nav) {
            const flatNavigation = this._fuseNavigationService.getFlatNavigation(nav);
            const fnd = flatNavigation.filter((item) => {
                return item.type.toLowerCase().includes('basic') && item.id === homePage && (item.link.startsWith('/dashboards') || item.link.startsWith('/devices'));
            });
            if (fnd && fnd.length > 0) {
                return fnd[0].link;
            }
        }

        return '';
    }

    refreshUserToken() {

        if (window.location.href.indexOf('reset-password') > -1) {
            debug('AUTH SERVICE', 'Refresh Token - skip');
            return of(this._userService.user);
        } else if (window.location.href.indexOf('google/redirect') > -1) {
            debug('AUTH SERVICE', 'Refresh Token - skip');
            return of(this._userService.user);
        } else if (window.location.href.indexOf('azure/redirect') > -1) {
            debug('AUTH SERVICE', 'Refresh Token - skip');
            return of(this._userService.user);
        } else if (window.location.href.indexOf('okta/redirect') > -1) {
            debug('AUTH SERVICE', 'Refresh Token - skip');
            return of(this._userService.user);
        } else if (window.location.pathname === '/' || window.location.pathname === '/home') {
            debug('AUTH SERVICE', 'Refresh Token - Home Page skip');
            return of(this._userService.user);
        } else if (this.methodId === '') {
            debug('AUTH SERVICE', 'Refresh Token - skip, missing method');
            return of(this._userService.user);
        } else {
            if (this.methodId === '1') {
                debug('AUTH SERVICE', 'Refresh Token');
                return this._httpClient.get<any>(this.baseURL + '/api/v3/user/token')
                    .pipe(map((user) => {

                        // Store the access token in the local storage
                        this.accessToken = user.accessToken;

                        // Set the authenticated flag to true
                        this._authenticated = true;

                        // Store the user on the user service
                        this._userService.user = user;

                        this.startRefreshTokenTimer();
                        return user;
                    }));
            } else {
                if (this.loginEmail === '') {
                    debug('AUTH SERVICE', 'Refresh Token - skip, missing email');
                    return of(this._userService.user);
                }

                debug('AUTH SERVICE', 'Refresh Token Alt');
                let body = {};
                let api = 'unknown';

                switch (this.methodId) {
                    case '2':
                        api = '/api/v3/user/azure/refresh';
                        body = { userEmail: this.loginEmail }
                        break;
                    case '3':
                        api = '/api/v3/user/google/refresh';
                        body = { userEmail: this.loginEmail }
                        break;
                    case '4':
                        api = '/api/v3/user/okta/refresh';
                        body = {
                            refreshToken: this.refreshToken,
                            frontendURL: this.frontendUrl
                        };
                        break;
                }
                return this._httpClient.post<User>(this.baseURL + api, body, this._httpOptions)
                    .pipe(map((user) => {
                        // Store the access token in the local storage
                        this.accessToken = user.accessToken;

                        // Set the authenticated flag to true
                        this._authenticated = true;

                        // Store the user on the user service
                        this._userService.user = user;

                        this.startRefreshTokenTimer();
                        return user;
                    }));
            }
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Helper methods
    // -----------------------------------------------------------------------------------------------------

    private refreshTokenTimeout;

    private startRefreshTokenTimer() {
        debug('AUTH SERVICE', 'Start Refresh Token Timer');

        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(this.accessToken.split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        const mins = Math.round(timeout / 60000);
        debug('AUTH SERVICE', `timeout = ${mins} minutes`);
        this.refreshTokenTimeout = setTimeout(() => this.refreshUserToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        debug('AUTH SERVICE', 'Stop Refresh Token Timer');
        clearTimeout(this.refreshTokenTimeout);
    }
}
