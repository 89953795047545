import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseNavigationItem, FuseNavigationService } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { defaultNavigation } from 'app/mock-api/common/navigation/data';
import { RoomService } from 'app/modules/admin/rooms/room/room.service';
//import { contacts } from 'app/mock-api/apps/contacts/data';

@Injectable({
    providedIn: 'root'
})
export class SearchMockApi {
    private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;

    /**
     * Constructor
     */
    constructor(
        private _fuseMockApiService: FuseMockApiService,
        private _fuseNavigationService: FuseNavigationService,
        private _roomService: RoomService
    ) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // Get the flat navigation and store it change
        let nav: FuseNavigationItem[] = this._defaultNavigation;

        try {
            const menu = localStorage.getItem('menu_items');
            if (menu) {
                nav = JSON.parse(menu);
            }
        } catch (e) {
            nav = this._defaultNavigation;
        }

        const flatNavigation = this._fuseNavigationService.getFlatNavigation(nav);

        // -----------------------------------------------------------------------------------------------------
        // @ Search results - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPost('api/common/search')
            .reply(({ request }) => {

                // Get the search query
                const query = cloneDeep(request.body.query.toLowerCase());

                // If the search query is an empty string,
                // return an empty array
                if (query === '') {
                    return [200, { results: [] }];
                }
                // Filter the rooms
                const roomResults = this._roomService.searchRooms(query);

                // Filter the navigation
                const pagesResults = cloneDeep(flatNavigation)
                    .filter(page => (page.title?.toLowerCase().includes(query) || (page.subtitle && page.subtitle.includes(query))));

                // Prepare the results array
                const results = [];

                // If there are room results...
                if (roomResults.length > 0) {
                    // Normalize the results
                    roomResults.forEach((result: any) => {

                    });

                    // Add to the results
                    results.push({
                        id: 'rooms',
                        label: 'Rooms',
                        results: roomResults
                    });
                }

                // If there are page results...
                if (pagesResults.length > 0) {
                    // Normalize the results
                    pagesResults.forEach((result: any) => {

                    });

                    // Add to the results
                    results.push({
                        id: 'pages',
                        label: 'Pages',
                        results: pagesResults
                    });
                }

                // Return the response
                return [200, results];
            });
    }
}
