// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // Development
    baseUrl: 'https://api-dev.hotdash.net',
    frontendUrl: 'https://development.hotdash.net',
    // frontendUrl: 'http://localhost:4200',
    DEVICE_IMAGE_ENDPOINT: 'https://api-dev.hotdash.net/api/v1/assets/devices',
    IDENTIFY_IMAGE_ENDPOINT: 'https://api-dev.hotdash.net/api/v1/assets/identify',
    SOCKET_ENDPOINT: 'https://api-dev.hotdash.net',
    urlWarning: true,
    newSiteUrl: 'https://resortsworld.hotdash.net'

    // //Localhost
    // baseUrl: 'http://localhost:3001',
    // frontendUrl: 'http://localhost:4200',
    // DEVICE_IMAGE_ENDPOINT: 'http://localhost:3001/api/v1/assets/devices',
    // IDENTIFY_IMAGE_ENDPOINT: 'http://localhost:3001/api/v1/assets/identify',
    // SOCKET_ENDPOINT: 'http://localhost:3001',
    // urlWarning: false,
    // newSiteUrl: 'https://resortsworld.hotdash.net'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
